import React from 'react'
import { Container, AppBar, Typography, Box, Link } from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import './App.css'
import Schedule from './Schedule'

const color = {
  palette: {
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#ccc',
      contrastText: '#000',
    },
    primary: {
      light: '#2c2c2c',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
}

const mainTheme = createMuiTheme({
  typography: {
    fontFamily: ["'Noto Serif JP'", 'sans-serif'].join(','),
  },
  ...color,
})

const headerTheme = createMuiTheme({
  typography: {
    fontFamily: ["'Great Vibes'", 'cursive'].join(','),
  },
  ...color,
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <Box>
        <ThemeProvider theme={headerTheme}>
          <AppBar position="static" color="secondary">
            <Box py={1}>
              <Typography variant="h6" align="center">
                Schedule Maker
              </Typography>
            </Box>
          </AppBar>
        </ThemeProvider>
        <Container>
          <Schedule />
        </Container>
        <Box pt={5} pb={6}>
          <Typography variant="body2" align="center">
            <Link underline="always" href="https://therapippi.com">
              セラピのスケジュールメーカー
            </Link>
            &nbsp;by&nbsp;
            <Link
              underline="always"
              href="https://twitter.com/katsukatsu_mo"
              target="_blank"
            >
              カツ男
            </Link>
          </Typography>
          <Typography variant="body2" align="center">
            <Link
              underline="always"
              href="https://katsuo.tokyo"
              target="_blank"
            >
              メンズエステ体験ブログ
            </Link>
            も！
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
