import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { Add, Cancel, Done } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import * as _ from 'lodash'
import { CompactPicker } from 'react-color'
//@ts-ignore
import * as loadImage from 'blueimp-load-image'
import defaultImage from './images/sample.jpg'
import { Font, AppFont } from './fonts/AppFont'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')
const FontFaceObserver = require('fontfaceobserver')

const useStyles = makeStyles(theme => ({
  gridItem: {
    textAlign: 'center',
  },
}))

interface Shift {
  day: number
  dayOfWeek: string
  startHour: number
  startMinutes: number
  endHour: number
  endMinutes: number
}

interface Schedule extends Array<Shift> {}

interface State {
  items: Schedule
  font: Font
  fontColor: string
  file: File | string
}

const dayOfWeekLabels = ['日', '月', '火', '水', '木', '金', '土']

const defaultShift: Shift = {
  day: dayjs().date(),
  dayOfWeek: dayOfWeekLabels[dayjs().day()],
  startHour: 12,
  startMinutes: 0,
  endHour: 20,
  endMinutes: 0,
}

const initialState: State = {
  items: [_.cloneDeep(defaultShift)],
  font: AppFont[0],
  fontColor: '#fff',
  file: defaultImage,
}

const Component: React.FC = () => {
  const classes = useStyles()
  const devicePixelRatio = window.devicePixelRatio
  const canvasWidth = 300 * devicePixelRatio
  const canvasHeight = 150 * devicePixelRatio
  const [state, setState] = useState(initialState)
  const [imageState, setImageState] = useState({ imageURL: '' })

  useEffect(() => {
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    canvas.width = canvasWidth
    canvas.height = canvasHeight
    canvas.style.width = `${canvasWidth / devicePixelRatio}px`
    canvas.style.height = `${canvasHeight / devicePixelRatio}px`
    let ctx = canvas.getContext('2d')!
    if (typeof state.file != 'string') {
      if (!state.file.type.match('image.*')) {
        alert('画像を選択してください')
        return
      }
    }
    const font = new FontFaceObserver(state.font.name)
    font.load().then(() => {
      loadImage.parseMetaData(state.file, (data: any) => {
        const option = {
          orientation: data?.exif?.get('Orientation'),
        }
        loadImage(
          state.file,
          (canvasWithImage: HTMLCanvasElement) => {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight)
            const scale = Math.max(
              canvasWidth / canvasWithImage.width,
              canvasHeight / canvasWithImage.height
            )
            const x = canvasWidth / 2 - (canvasWithImage.width / 2) * scale
            const y = canvasHeight / 2 - (canvasWithImage.height / 2) * scale
            ctx.drawImage(
              canvasWithImage,
              x,
              y,
              canvasWithImage.width * scale,
              canvasWithImage.height * scale
            )
            const { size, spaceX, spaceY } = state.font
            ctx.font = `${size}px ${state.font.name}`
            ctx.fillStyle = state.fontColor
            ctx.textBaseline = 'middle'
            if (state.items.length >= 6) {
              ctx.textBaseline = 'middle'
              const firstLineSpaceY = size * 2.2
              let textStartY = firstLineSpaceY
              ctx.textAlign = 'start'
              state.items.forEach((item, index) => {
                const text = generateShiftText(item)
                let textStartX: number
                if (index === 5) {
                  textStartY = firstLineSpaceY
                }
                if (index >= 5) {
                  textStartX = canvasWidth / 2 + spaceX * 0.5
                } else {
                  textStartX = spaceX
                }
                ctx.fillText(text, textStartX, textStartY)
                textStartY = textStartY + spaceY
              })
            } else {
              let textStartY = canvasHeight / 2
              textStartY = textStartY - (state.items.length - 1) * size * 0.925
              ctx.textAlign = 'center'
              state.items.forEach(item => {
                ctx.fillText(
                  generateShiftText(item),
                  canvasWidth / 2,
                  textStartY
                )
                textStartY = textStartY + spaceY
              })
            }
            const img = new Image()
            const imageURL = canvas.toDataURL() as string
            img.src = imageURL
            img.onload = () => {
              setImageState({ imageURL: imageURL })
              console.log('update image url')
              // HACK: 2回目以降のアップロードで何故か画面を触らないと更新されないので強制的にちょっと動かす
              window.scrollTo(0, window.pageYOffset + 1)
              window.scrollTo(0, window.pageYOffset - 1)
            }
          },
          option
        )
      })
    })
  }, [state, canvasWidth, canvasHeight, devicePixelRatio])

  const handleFontSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fontName = event.target.value as string
    const font = AppFont.find(({ name }) => name === fontName)
    setState({ ...state, font: font! })
  }

  const addShift = () => {
    const newSchedule: Schedule = [...state.items]
    newSchedule.push(_.cloneDeep(_.last(newSchedule)!))
    setState({ ...state, items: newSchedule })
  }

  const updateShift = (
    shiftIndex: number,
    key: 'day' | 'startHour' | 'startMinutes' | 'endHour' | 'endMinutes',
    value: number
  ) => {
    const newSchedule: Schedule = [...state.items]
    newSchedule[shiftIndex][key] = value
    if (key === 'day') {
      let date: Date
      if (dayjs().date() > value) {
        date = new Date(dayjs().year(), dayjs().month(), value)
        date.setMonth(date.getMonth() + 1)
      } else {
        date = new Date(dayjs().year(), dayjs().month(), value)
      }
      newSchedule[shiftIndex].dayOfWeek = dayOfWeekLabels[dayjs(date).day()]
    }
    if (key === 'startHour') {
      let endHour = value + 8
      if (endHour > 29) {
        endHour = 29
      }
      newSchedule[shiftIndex].endHour = endHour
    }
    setState({ ...state, items: newSchedule })
  }

  const deleteShift = (shiftIndex: number) => {
    const newSchedule: Schedule = [...state.items]
    newSchedule.splice(shiftIndex, 1)
    setState({ ...state, items: newSchedule })
  }

  const copyText = () => {
    let text = ''
    state.items.forEach(item => {
      text = text + generateShiftText(item, true)
    })
    const textarea: HTMLTextAreaElement = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const range = document.createRange()
      range.selectNodeContents(textarea)
      const selection = getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)
      textarea.setSelectionRange(0, 999999)
    } else {
      textarea.select()
    }
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  const normalizeZero = (number: number) => {
    return number === 0 ? '00' : number
  }

  const generateShiftText = (item: Shift, includeLineFeedCode?: boolean) => {
    const startMinutes = normalizeZero(item.startMinutes)
    const endMinutes = normalizeZero(item.endMinutes)
    return `${item.day}（${item.dayOfWeek}）${
      item.startHour
    }:${startMinutes}〜${item.endHour}:${endMinutes}${
      includeLineFeedCode ? '\n' : ''
    }`
  }

  const menuItems = (keyName: 'month' | 'day' | 'hour' | 'minutes') => {
    var numbers: Array<number>
    switch (keyName) {
      case 'month':
        numbers = _.range(1, 13)
        break
      case 'day':
        numbers = _.range(1, 32)
        break
      case 'hour':
        numbers = _.range(10, 30)
        break
      case 'minutes':
        numbers = _.range(0, 60, 10)
        break
    }
    return numbers.map(item => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))
  }

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.gridItem}>
            <Input
              inputProps={{ accept: 'image/*' }}
              type={'file'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState({ ...state, file: e.target.files![0] })
              }}
            ></Input>
          </Box>
          <Box
            className={classes.gridItem}
            hidden={!imageState.imageURL}
            mt={2}
          >
            <img
              src={imageState.imageURL}
              alt="スケジュール画像"
              width={canvasWidth}
              height={canvasHeight}
              style={{
                width: `${canvasWidth / devicePixelRatio}px`,
                height: `${canvasHeight / devicePixelRatio}px`,
                borderRadius: '4px',
              }}
            />
          </Box>
          <Box className={classes.gridItem} mt={2}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="position"
                name="position"
                value={state.font.name}
                onChange={event => {
                  handleFontSelected(event)
                }}
                row
              >
                {AppFont.map((font, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={font.name}
                      control={<Radio color="primary" />}
                      label={font.label}
                      labelPlacement="end"
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className={classes.gridItem} mt={2}>
            <CompactPicker
              color={state.fontColor}
              onChangeComplete={color => {
                setState({ ...state, fontColor: color.hex })
              }}
            />
          </Box>
          <Box className={classes.gridItem} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Done />}
              onClick={copyText}
            >
              文字をコピー
            </Button>
          </Box>
        </Grid>
        {state.items.map((shift, index) => {
          return (
            <Grid container key={index}>
              <Grid item xs={6}>
                <Box className={classes.gridItem}>
                  <FormControl>
                    <InputLabel>日</InputLabel>
                    <Select
                      value={shift.day}
                      onChange={e => {
                        updateShift(index, 'day', e.target.value as number)
                      }}
                    >
                      {menuItems('day')}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>曜日</InputLabel>
                    <Select
                      value={shift.dayOfWeek}
                      onChange={e => {
                        const weekOfDay = e.target.value as string
                        const newSchedule: Schedule = [...state.items]
                        newSchedule[index].dayOfWeek = weekOfDay
                        setState({ ...state, items: newSchedule })
                      }}
                    >
                      {['月', '火', '水', '木', '金', '土', '日'].map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>時</InputLabel>
                    <Select
                      value={shift.startHour}
                      onChange={e => {
                        updateShift(
                          index,
                          'startHour',
                          e.target.value as number
                        )
                      }}
                    >
                      {menuItems('hour')}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>分</InputLabel>
                    <Select
                      value={shift.startMinutes}
                      onChange={e => {
                        updateShift(
                          index,
                          'startMinutes',
                          e.target.value as number
                        )
                      }}
                    >
                      {menuItems('minutes')}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box mt={2} className={classes.gridItem}>
                  <Typography>~</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.gridItem}>
                  <FormControl>
                    <InputLabel>時</InputLabel>
                    <Select
                      value={shift.endHour}
                      onChange={e => {
                        updateShift(index, 'endHour', e.target.value as number)
                      }}
                    >
                      {menuItems('hour')}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>分</InputLabel>
                    <Select
                      value={shift.endMinutes}
                      onChange={e => {
                        updateShift(
                          index,
                          'endMinutes',
                          e.target.value as number
                        )
                      }}
                    >
                      {menuItems('minutes')}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box mt={2} className={classes.gridItem}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      deleteShift(index)
                    }}
                  >
                    <Cancel fontSize="small"></Cancel>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Box className={classes.gridItem}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Add />}
              onClick={() => {
                addShift()
              }}
              disabled={state.items.length >= 10}
            >
              追加
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Component
