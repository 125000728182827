export interface Font {
  name: string
  label: string
  size: number
  spaceX: number
  spaceY: number
}

interface AppFont extends Array<Font> {}

const fontSize =
  parseFloat(getComputedStyle(document.documentElement).fontSize) *
  window.devicePixelRatio

const AppFont: AppFont = [
  {
    name: 'mplus-2c-regular',
    label: 'シンプル',
    size: fontSize * 0.8,
    spaceX: fontSize * 0.5,
    spaceY: fontSize * 1.5,
  },
  {
    name: 'RiiTN_R',
    label: '手書き風',
    size: fontSize * 0.8,
    spaceX: fontSize * 0.5,
    spaceY: fontSize * 1.5,
  },
  {
    name: 'OradanoGSRR',
    label: 'かっちり',
    size: fontSize * 0.8,
    spaceX: fontSize * 1.75,
    spaceY: fontSize * 1.5,
  },
]

export { AppFont }
